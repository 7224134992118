<template>
  <div class="dashboard-container">

    <!-- GETTING STARTED -->
    <div class="card-default" v-if="(!customerConfiguration.dpp_accepted_by_username && user.is_customer_admin)">

      <h3>{{ customer.name }}</h3>
      <h3>{{ $t("dashboard.card1.heading2") }}</h3>

      <!-- CHECK LIST -->
      <div class="check-lists">

        <p>{{ $t("dashboard.card1.description") }}</p>

        <div class="mt-1">
          <vs-checkbox v-if="!customerConfiguration.dpp_accepted_by" disabled v-model="checkBox1">
            <router-link class="no-underline" :to="{ name: 'DataAgreement'}">{{ $t("dashboard.card1.list-item1") }}</router-link>
          </vs-checkbox>
        </div>
        <!-- <div class="mt-1">
          <vs-checkbox v-if="organizations.length === 0" disabled>
            <router-link class="no-underline" :to="{ name: 'Organizations'}">{{ $t("dashboard.card1.list-item2") }}</router-link>
          </vs-checkbox>
        </div>
        <div class="mt-1" v-if="!hasCreatedFirstOrganization">
          <router-link class="no-underline" :to="{ name: 'Users'}">{{ $t("dashboard.card1.list-item3") }}</router-link>
        </div>-->
      </div>
    </div>

    <div class="sm-card-con">
      <div class="sm-card card-c mr-3">
        <h3>{{ customer.name }}</h3>

        <div>
          <p>{{organizations.length}} {{ $t("dashboard.card2.sub_head1") }}</p>
          <p>{{idCards.length}} {{ $t("dashboard.card2.sub_head2") }}</p>
        </div>
      </div>
      <div class="sm-card card-c ml-3 cursor" @click="$router.push('/amendments')">
        <h3>{{ $t("Änderungsanträge (offen)") }}</h3>
        <div class="sm-card-footer">
          <span class="text">{{ $t("dashboard.card3.sub_head1") }}</span>
          <h1 class="count" :class="dashboardStats.openChangeRequests>0 ? 'text-danger' : 'text-success'">{{dashboardStats.openChangeRequests}}</h1>
        </div>
      </div>
      <div class="sm-card card-c cursor mr-3" @click="$router.push('/exprired-id-cards')">
        <h3>{{ $t("dashboard.card4.heading1") }}</h3>
        <div class="sm-card-footer">
          <span class="text">{{ $t("dashboard.card4.sub_head1") }}</span>
          <h1 class="count" :class="dashboardStats.expiredIdCards>0 ? 'text-danger' : 'text-success'">{{dashboardStats.expiredIdCards}}</h1>
        </div>
      </div>
      <div class="sm-card card-c ml-3" @click="$router.push('/Unapproved-id-cards')">

        <h3>{{ $t("dashboard.card5.heading1") }}</h3>
        <div class="sm-card-footer">
          <span class="text">{{ $t("dashboard.card5.sub_head1") }}</span>
          <h1 class="count" :class="dashboardStats.openIdCards>0 ? 'text-danger' : 'text-success'">{{dashboardStats.openIdCards}}</h1>
        </div>
      </div>
    </div>
    <div class="sm-card-con">
      <div class="sm-card mr-3" @click="$router.push('/support')">
        <span><img src="/icons/support.svg" alt="" /></span>
        <span class="text ml-2">{{ $t("dashboard.sub_card1.heading") }}</span>
      </div>
      <div class="sm-card ml-3" @click="$router.push('/law')">
        <span><img src="/icons/laws.svg" alt="" /></span>
        <span class="text ml-2">{{ $t("dashboard.sub_card2.heading") }}</span>
      </div>
    </div>


    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">
      <h1>has cretaed first organisation: </h1>
      {{hasCreatedFirstOrganization}}

      <h1>USER</h1>
      <p>
        {{ user }}
      </p>
      <h1>USERS</h1>
      <p>
        {{ users }}
      </p>
      <h1>DASHBOARDSTATS</h1>
      <p>
        {{ dashboardStats }}
      </p>

      <h1>REQUESTS</h1>
      <p>
        {{ changeRequests }}
      </p>

      <h1>ID-Cards</h1>
      <p>
        {{ idCards }}
      </p>

      <h1>organizations</h1>
      <p>
        {{ organizations }}
      </p>
      <h1>CUSTOMER</h1>
      <p>
        {{ customer }}
      </p>


    </div>
  </div>
</template>

<script>
import Vue from "vue";


export default Vue.extend({
  name: "Dashboard",

  data() {
    return {
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,    
    };
  },

  async mounted() {
    const user = this.$store.getters['auth/getUser']
    await this.$store.dispatch('customer/loadCustomerConfiguration', user.customer_id)
    await this.$store.dispatch('customer/loadCustomer', user.customer_id)

    //  Load Stats
    // if (user.is_customer_admin) {
    //   // console.log(error("is_customer_admin")
    //   await this.$store.dispatch('core/loadDashboardStatsByCustomer', user.customer_id)
    // }
    await this.$store.dispatch('core/loadDashboardStats')

    // await this.$store.dispatch("changeRequest/loadChangeRequests");
    await this.$store.dispatch('organization/loadOrganizations')
    await this.$store.dispatch('idCard/loadIdCards')
    // await this.$store.dispatch('user/loadUsers')
  },

  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
    users() {
      return this.$store.getters["user/getUsers"]
    },
    customer() {
      return this.$store.getters['customer/getCustomer']
    },
    dashboardStats() {
      return this.$store.getters['core/getDashboardStats']
    },

    hasCreatedFirstOrganization() {
      let organizations = this.$store.getters["organization/getOrganizations"]
      if (organizations.find( orga => orga.created_by === this.user.id)) {
        return true
      }
      return false
    },
    changeRequests() {
      return this.$store.getters["changeRequest/getChangeRequests"];
    },
    organizations() {
      return this.$store.getters["organization/getOrganizations"]
    },
    idCards() {
      return this.$store.getters["idCard/getIdCards"]
    },
    customerConfiguration() {
        return this.$store.getters['customer/getCustomerConfiguration']
    },
  },
});
</script>


<style>
.no-underline {
  text-decoration: none;
  color: #5196D7;
}
table th {
  color: #6e6e6e !important;
  background-color: #f7f7f7;
  cursor: pointer;
  padding: 8px;
  min-width: 30px;
}
table th:hover {
    background: rgb(218, 222, 230);
}
.arrow_down {
  min-width: 30px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAYAAABPY4eKAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1PGlEUHQaiiewslpUJiyYs2yb9AyRuJGm7c0VJoFXSX9A0sSZN04ULF12YEBQDhMCuSZOm1FhTiLY2Rky0QPlQBLRUsICoIN/0PCsGyox26NC3eTNn3r3n3TvnvvsE1PkwGo3yUqkkEQqFgw2Mz7lWqwng7ztN06mxsTEv8U0Aam5u7r5EInkplUol/f391wAJCc7nEAgE9Uwmkzo4OPiJMa1Wq6cFs7Ozt0H6RqlUDmJXfPIx+qrX69Ti4mIyHA5r6Wq1egND+j+IyW6QAUoul18XiUTDNHaSyGazKcZtdgk8wqhUKh9o/OMvsVgsfHJy0iWqVrcQNRUMBnd6enqc9MjISAmRP3e73T9al3XnbWNjIw2+KY1Gc3imsNHR0YV4PP5+d3e32h3K316TySQFoX2WyWR2glzIO5fLTSD6IElLNwbqnFpbWyO/96lCoai0cZjN5kfYQAYi5H34fL6cxWIZbya9iJyAhULBHAqFVlMpfsV/fHxMeb3er+Vy+VUzeduzwWC45XA4dlD/vEXvdDrj8DvURsYEWK3WF4FA4JQP9mg0WrHZbEYmnpa0NxYgPVObm5teiLABdTQT8a6vrwdRWhOcHMzMzCiXlpb2/yV6qDttMpkeshEzRk4Wo/bfoe4X9vb2amzGl+HoXNT29vZqsVi0sK1jJScG+Xx+HGkL4Tew2TPi5zUdQQt9otPpuBk3e0TaHmMDh1zS7/f780S0zX6Yni+NnBj09fUZUfvudDrNZN+GkQbl8Xi8RLRtHzsB9Hr9nfn5+SjSeWUCXC7XPq5kw53wsNogjZNohYXL2EljstvtrAL70/mVaW8Y4OidRO1/gwgbUMvcqGmcDc9aPvD1gnTeQ+0nmaInokRj0nHh+uvIiVOtVvt2a2vLv7Ky0tL3cRTXIcpPAwMDpq6R4/JXE4vFQ5FI5CN+QTaRSFCYc8vLy1l0rge4ARe5kJ/d27kYkLXoy2Jo4C7K8CZOsEBvb+9rlUp1xNXPL7v3IDwxvPD6AAAAAElFTkSuQmCC')
}
.arrow_up {
  min-width: 30px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAwpJREFUSA21Vt1PUmEYP4dvkQ8JFMwtBRocWAkDbiqXrUWXzU1rrTt0bdVqXbb1tbW16C9IBUSmm27cODdneoXjputa6069qwuW6IIBIdLvdaF4OAcOiGeDc87zPs/vd57P96WpFq7p6enbGo1mjKZpeTabjU1MTCRagGnOZHFxcXxtbe1XKpUq7+zslJeXl//Mz8+Hy+Uy3RxSE9qTk5M3otFooVQqgef4Wl9f343FYoEmoISrxuNxFX5f9vb2jhn/PxUKhfLS0tIPfFifUESRUMV8Pv/M6XReRm5rTGQyGeXxeGxYe1ezeBpBOBx2rKysbO7v79d4Wy3Y2Nj4GQqFbgnhaugxwiuGJx99Pp9FLBbXxYTXvTqd7v3MzIy6riIWGxJnMpl7AwMD14xGYyMsSq1WUyQdUqn0eSPlusQIsbGrq+vl4OCgvhFQZd1utyv1en0gEolcqsi47nWJlUrlG5fLZVcoFFy2nDKSDpIWlUoVTCQSEk4lCHmJMZ2GTCbTiMVikfIZ88l7enoos9l8dXt7+z6fDicxSJUokqDX6xXcl2wCROoc0vQCWL3sNfLOSdzR0fHY4XC4tVotl40gmVwup9xuN4OQv+UyqCFGH9rg7SOGYVRcBs3IEG4J0nVnamrqOtvuBDGGgQg9+wHFcVEi4a0LNkbdd6TrPKo8ODc311mteIIYjT/a398/jK+s1jnVM0kXoufCFvq0GuiIGEVgQIhfoygM1QrteEa9dAL7ITiYCt4RMabOK5AyKKzKWtvupLcRciu8D5J0EuDDPyT/Snd39yh6VtY2NhYQSR9G79Ds7OxdskRjEyAufvb7/cPoO5Z6e1+xtVKrq6vfcFzyi/A3ZrPZ3GdNSlwgo5ekE4X2RIQGf2C1WlufFE0GBeGWYQ8YERWLxQtnUVB830MKLZfL9RHir8lkssCn2G751tZWEWe03zTKm15YWPiEiXXTYDB0Ig/t7yd8PRws4EicwWHxO4jHD8/C5HiTTqd1BwcHFozKU89origB+y/kmzgYpgOBQP4fGmUiZmJ+WNgAAAAASUVORK5CYII=')
}
.arrow {
  width: 12px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  display:inline-block;
  border-left: 8px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  vertical-align: middle;
}
</style>